import { $toNormalCase } from "@/helper/globalFunction";
export default {
  name: 'muscoskeletalMotorMixin',
  data: () => ({
    content: {
      pdfPage: {
        muscoskeletalMotorAssessment: [
          { question: "Proximal muscle weakness in upper extremities" },
          { question: "Status since last assessment" },
          { question: "Distal muscle weakness in upper extremities " },
          { question: "Status since the last assessment", },
          { question: "Proximal muscle weakness in lower extremities", },
          { question: "Status since the last assessment", },
          { question: "Distal muscle weakness in lower extremities" },
          { question: "Status since the last assessment" },
          { question: "Muscle weakness in trunk" },
          { question: "Status since the last assessment" },
          { question: "Is the patient currently ambulatory" },
          { question: "Ability" },
          { question: "If ambulatory with difficulty" },
          { question: "Currently using ambulation devices", },
          { question: "Status since the last assessment" },
          { question: "Type of support" }
        ]
      }
    }
  }),
  methods: {
    $toNormalCase,
    assignMuscoskeletalMotor(data, date) {
      this.content.pdfPage.muscoskeletalMotorAssessment[0][date] = 
        this.$toNormalCase(data.proximalWeaknessInUpperExtremities.isPresent);
      this.content.pdfPage.muscoskeletalMotorAssessment[1][date] = 
        this.$toNormalCase(data.proximalWeaknessInUpperExtremities.theStatus);
      this.content.pdfPage.muscoskeletalMotorAssessment[2][date] = 
        this.$toNormalCase(data.distalWeaknessInUpperExtremities.isPresent);
      this.content.pdfPage.muscoskeletalMotorAssessment[3][date] = 
        this.$toNormalCase(data.distalWeaknessInUpperExtremities.theStatus);
      this.content.pdfPage.muscoskeletalMotorAssessment[4][date] = 
        this.$toNormalCase(data.proximalWeaknessInLowerExtremities.isPresent);
      this.content.pdfPage.muscoskeletalMotorAssessment[5][date] = 
        this.$toNormalCase(data.proximalWeaknessInLowerExtremities.theStatus);
      this.content.pdfPage.muscoskeletalMotorAssessment[6][date] = 
        this.$toNormalCase(data.distalWeaknessInLowerExtremities.isPresent);
      this.content.pdfPage.muscoskeletalMotorAssessment[7][date] = 
        this.$toNormalCase(data.distalWeaknessInLowerExtremities.theStatus);
      this.content.pdfPage.muscoskeletalMotorAssessment[8][date] = 
        this.$toNormalCase(data.weaknessInTrunk.isPresent);
      this.content.pdfPage.muscoskeletalMotorAssessment[9][date] = 
        this.$toNormalCase(data.weaknessInTrunk.theStatus);
      this.content.pdfPage.muscoskeletalMotorAssessment[10][date] = 
        this.$toNormalCase(data.patientCurrentlyInAmbulatory);
      this.content.pdfPage.muscoskeletalMotorAssessment[13][date] = 
        this.$toNormalCase(data.currentlyUsingAmbulatoryDevice);
      this.content.pdfPage.muscoskeletalMotorAssessment[14][date] = 
        this.$toNormalCase(data.patientAmbulatoryStatus);
      this.content.pdfPage.muscoskeletalMotorAssessment[15][date] = 
        this.$toNormalCase(data.ambulatoryDevice)
    }
  }
};