export default {
  name: 'waltonGardnerMelwinScale',
  data: () => ({
    content: {
      pdfPage: {
        waltonGardnerMewinScale: [
          { question: 'Exam Date' },
          { question: 'Grade' }
        ]
      }
    }
  }),
  methods: {
    assignWaltonGardner(data, date) {
      this.content.pdfPage.waltonGardnerMewinScale[0][date] = this.formatDateAbvMonth(data.dateOfWaltonGardnerMewinScale);
      this.content.pdfPage.waltonGardnerMewinScale[1][date] = data.waltonGardnerMewinScaleGrade;
    }
  }
};