import { $toNormalCase } from "@/helper/globalFunction";

export default {
  name: 'sixMinuteWalkTestMixin',
  data: () => ({
    content: {
      pdfPage: {
        sixMinuteWalkTest: [
          { question: 'Date of 6MWT' },
          { question: 'Start Time of 6MWT' },
          { question: 'End Time of 6MWT' },
          { question: 'Duration of test'  },
          { question: 'Stopped or paused before 6 minutes'  },
          { question: 'Assistive Walking Devices and Orthoses'  },
          { question: 'Was the patient on respiratory support (e.g.,ventilation, CPAP) during the test'  },
          { question: 'Pre-test blood pressure (systolic/diastolic)'  },
          { question: 'Pre-test heart rate'  },
          { question: 'Post-test heart rate'  },
          { question: 'Pre-test dyspnoe (Borg Scale)'  },
          { question: 'Post-test dyspnoe (Borg Scale)'  },
          { question: 'Pre-test fatigue (Borg Scale)'  },
          { question: 'Post-test fatigue (Borg Scale)'  },
          { question: 'Other symptoms at end of exercise'  },
          { question: 'Total distance walked' },
          { question: "Type of Respiratory Support" }
        ]
      }
    }
  }),
  methods: {
    $toNormalCase,
    assignSixMinuteWalkTest(data, date) {
      this.content.pdfPage.sixMinuteWalkTest[0][date] = this.formatDateAbvMonth(data.dateOf6MWT);
      this.content.pdfPage.sixMinuteWalkTest[1][date] = `${data.startTime6MWTHour}:${data.startTime6MWTMinute}`;
      this.content.pdfPage.sixMinuteWalkTest[2][date] = `${data.endTime6MWTHour}:${data.endTime6MWTMinute}`;
      this.content.pdfPage.sixMinuteWalkTest[3][date] = this.$toNormalCase(data.durationTest);
      this.content.pdfPage.sixMinuteWalkTest[4][date] = this.$toNormalCase(data.stoppedBefore6Minutes);
      this.content.pdfPage.sixMinuteWalkTest[5][date] = this.$toNormalCase(data.assistiveWalkingDevices);
      this.content.pdfPage.sixMinuteWalkTest[6][date] = this.$toNormalCase(data.respiratorySupportGiven);
      this.content.pdfPage.sixMinuteWalkTest[7][date] = `${data.pretestBloodPressureSystolic}/${data.pretestBloodPressureDiastolic}`;
      this.content.pdfPage.sixMinuteWalkTest[8][date] = data.pretestHeartRate;
      this.content.pdfPage.sixMinuteWalkTest[9][date] = data.posttestHeartRate;
      this.content.pdfPage.sixMinuteWalkTest[10][date] = data.pretestDyspnoe;
      this.content.pdfPage.sixMinuteWalkTest[11][date] = data.posttestDyspnoe;
      this.content.pdfPage.sixMinuteWalkTest[12][date] = data.pretestFatigue;
      this.content.pdfPage.sixMinuteWalkTest[13][date] = data.posttestFatigue;
      this.content.pdfPage.sixMinuteWalkTest[14][date] = this.$toNormalCase(data.otherSymptomsEndExercise);
      this.content.pdfPage.sixMinuteWalkTest[15][date] = data.totalDistanceWalked;
      this.content.pdfPage.sixMinuteWalkTest[16][date] = this.$toNormalCase(data.typeRespiratorySupport);
    }
  }
};
