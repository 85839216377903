import { $toNormalCase } from "@/helper/globalFunction";

export default {
  name: 'muscoskeletalMotorMixin',
  data: () => ({
    content: {
      pdfPage: {
        motorFunctionalActivities: [
          { question: 'Exam Date' },
          { question: 'Walking 10 meters' },
          { question: 'Assistance' }
        ]
      }
    }
  }),
  methods: {
    $toNormalCase,
    assignMotorFunctional(data, date) {
      this.content.pdfPage.motorFunctionalActivities[0][date] = 
        this.formatDateAbvMonth(data.dateOfMotorFunctionalActivities);
      this.content.pdfPage.motorFunctionalActivities[1][date] = data.walking10Meters;
      this.content.pdfPage.motorFunctionalActivities[2][date] = this.$toNormalCase(data.walking10MetersAssistance);
    }
  }
}