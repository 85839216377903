var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',{attrs:{"id":"evaluation-pdf-template"}},[_c('div',{staticClass:"flex mt-3"},[_vm._m(0),_c('div',{staticClass:"right"},[_c('button',{staticClass:"btn btn-danger btn-sm",on:{"click":_vm.exportToPDF}},[_vm._v(" Generate PDF ")])])]),_c('hr'),_c('div',{ref:"document"},[_c('h6',[_vm._v("Swiss Pompe Registry")]),_c('hr'),_c('div',{staticClass:"section"},[_c('h4',[_vm._v("1. Patient")]),_c('pompe-table',_vm._b({},'pompe-table',{
          showPagination: false,
          data: _vm.content.pdfPage.patients,
          columns: _vm.options.patients.columns
        },false))],1),_c('br'),_c('div',{staticClass:"section"},[_c('h4',[_vm._v("2. Evaluation")]),_c('pompe-table',_vm._b({key:_vm.updateComponent},'pompe-table',{
          showPagination: false,
          data: _vm.content.pdfPage.evaluation,
          columns: _vm.options.evaluation.columns
        },false))],1),_c('br'),_c('div',{staticClass:"section"},[_c('h4',[_vm._v("3. Assessment")]),_c('div',{staticClass:"section"},[_c('h5',[_vm._v("3.1 Six Minute Walk Test")]),_c('pompe-table',_vm._b({},'pompe-table',{
            showPagination: false,
            data: _vm.content.pdfPage.sixMinuteWalkTest,
            columns: _vm.options.sixMinuteWalkTest.columns
          },false))],1),_c('div',{staticClass:"section"},[_c('h6',{staticClass:"font-weight-light"},[_vm._v("Borg Scale")]),_c('pompe-table',_vm._b({},'pompe-table',{
            showPagination: false,
            data: _vm.options.sixMinuteWalkTest.scaleValues,
            columns: _vm.options.sixMinuteWalkTest.scaleColumn
          },false))],1),_c('br'),_c('div',{staticClass:"section"},[_c('h5',[_vm._v("3.2 MRC State")]),_c('pompe-table',_vm._b({},'pompe-table',{
              showPagination: false,
              data: _vm.content.pdfPage.mRCScale,
              columns: _vm.options.mRCScale.columns
            },false))],1),_c('div',{staticClass:"section"},[_c('h6',{staticClass:"font-weight-light"},[_vm._v("MRC Scale")]),_c('pompe-table',_vm._b({},'pompe-table',{
            showPagination: false,
            data: _vm.options.mRCScale.scaleValues,
            columns: _vm.options.mRCScale.scaleColumn
          },false))],1),_c('br'),_c('div',{staticClass:"section"},[_c('h4',[_vm._v("3.3 Pulmonary Function Test")]),_c('pompe-table',_vm._b({},'pompe-table',{
            showPagination: false,
            data: _vm.content.pdfPage.pulmonaryFunctionTest,
            columns: _vm.options.pulmonaryFunctionTest.columns
          },false))],1),_c('br'),_c('div',{staticClass:"section"},[_c('h4',[_vm._v("3.4 Motor Functional Activities")]),_c('pompe-table',_vm._b({},'pompe-table',{
            showPagination: false,
            data: _vm.content.pdfPage.motorFunctionalActivities,
            columns: _vm.options.motorFunctionalActivities.columns
          },false))],1),_c('br'),_c('div',{staticClass:"section"},[_c('h4',[_vm._v("3.5 Respiratory Assessment")]),_c('pompe-table',_vm._b({},'pompe-table',{
            showPagination: false,
            data: _vm.content.pdfPage.respiratoryAssessment,
            columns: _vm.options.respiratoryAssessment.columns
          },false))],1),_c('br'),_c('div',{staticClass:"section"},[_c('h4',[_vm._v("3.6 Muscoskeletal / Motor Assessment")]),_c('pompe-table',_vm._b({},'pompe-table',{
            showPagination: false,
            data: _vm.content.pdfPage.muscoskeletalMotorAssessment,
            columns: _vm.options.muscoskeletalMotorAssessment.columns
          },false))],1),_c('br'),_c('h5',[_vm._v("3.7 Modified Ranking Scale")]),_c('pompe-table',_vm._b({},'pompe-table',{
          showPagination: false,
          data: _vm.content.pdfPage.modifiedRankingScale,
          columns: _vm.options.modifiedRankingScale.columns
        },false))],1),_c('div',{staticClass:"section"},[_c('h6',{staticClass:"font-weight-light"},[_vm._v("Modified Ranking Scale")]),_c('pompe-table',_vm._b({},'pompe-table',{
          showPagination: false,
          data: _vm.options.modifiedRankingScale.scaleValues,
          columns: _vm.options.modifiedRankingScale.scaleColumn
        },false))],1),_c('br'),_c('div',{staticClass:"section"},[_c('h4',[_vm._v("3.8 Rotterdam 9-Item Handicap Scale")]),_c('pompe-table',_vm._b({},'pompe-table',{
          showPagination: false,
          data: _vm.content.pdfPage.rotterdam9ItemHandicapScale,
          columns: _vm.options.rotterdam9ItemHandicapScale.columns
        },false))],1),_c('br'),_c('div',{staticClass:"section"},[_c('h4',[_vm._v("3.9 Fatigue Severity Scale")]),_c('pompe-table',_vm._b({},'pompe-table',{
          showPagination: false,
          data: _vm.content.pdfPage.fatigueSeverityScale,
          columns: _vm.options.fatigueSeverityScale.columns
        },false))],1),_c('div',{staticClass:"section"},[_c('h6',{staticClass:"font-weight-light"},[_vm._v("Fatigue Severity Scale")]),_c('pompe-table',_vm._b({},'pompe-table',{
          showPagination: false,
          data: _vm.options.fatigueSeverityScale.scaleValues,
          columns: _vm.options.fatigueSeverityScale.scaleColumn
        },false))],1),_c('br'),_c('div',{staticClass:"section"},[_c('h4',[_vm._v("3.10 Walton & Gardner Melwin Scale")]),_c('pompe-table',_vm._b({},'pompe-table',{
          showPagination: false,
          data: _vm.content.pdfPage.waltonGardnerMewinScale,
          columns: _vm.options.waltonGardnerMewinScale.columns
        },false))],1),_c('div',{staticClass:"section"},[_c('h6',{staticClass:"font-weight-light"},[_vm._v("Walton & Gardner Melwin Scale")]),_c('pompe-table',_vm._b({},'pompe-table',{
          showPagination: false,
          data: _vm.options.waltonGardnerMewinScale.scaleValues,
          columns: _vm.options.waltonGardnerMewinScale.scaleColumn
        },false))],1),_c('br'),_c('div',{staticClass:"section"},[_c('h4',[_vm._v("3.11 Vital Signs")]),_c('pompe-table',_vm._b({},'pompe-table',{
          showPagination: false,
          data: _vm.content.pdfPage.vitalSigns,
          columns: _vm.options.vitalSigns.columns
        },false))],1)])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left"},[_c('h4',{staticClass:"mt-2 text-muted"},[_vm._v(" PDF Preview ")])])}]

export { render, staticRenderFns }