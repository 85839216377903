import { $toNormalCase } from "@/helper/globalFunction";

export default {
  name: 'respiratoryAssessment',
  data: () => ({
    content: {
      pdfPage: {
        respiratoryAssessment: [
          { question: "Is the Patient Currently receiving respiratory support" },
          { question: "Status since last assessment" },
          { question: "Current type of support" },
          { question: "Duration" },
          { question: "Type" },
          { question: "Date of First Use" }
        ]
      }
    },
    type: {
      cpap: "CPAP",
      bipapNIPPV: "BiPAP/NIPPV",
      other: "Other",
      unknown: "Unknown"
    },
    duration: {
      "24h/day": "24h/day",
      less24hNightAndDay: "<24h (Night and Day)",
      nightOnly: "Night Only", value: "nightOnly",
      unknown: "Unknown",
    }
  }),
  methods: {
    $toNormalCase,
    assignRespiratory(data, date) {
      this.content.pdfPage.respiratoryAssessment[0][date] = this.$toNormalCase(data.receivingRespiratorySupport);
      this.content.pdfPage.respiratoryAssessment[1][date] = this.$toNormalCase(data.statusSinceLastAssessment);

      if (data.currentTypeOfSupport) {
        data.currentTypeOfSupport = data.currentTypeOfSupport.map(el => this.$toNormalCase(el));
      }
      this.content.pdfPage.respiratoryAssessment[2][date] = data.currentTypeOfSupport ? data.currentTypeOfSupport.toString() : null;
      this.content.pdfPage.respiratoryAssessment[3][date] = this.duration[data.respiratoryAssessmentDuration];
      this.content.pdfPage.respiratoryAssessment[4][date] = this.type[data.respiratoryAssessmentType];
      this.content.pdfPage.respiratoryAssessment[5][date] = this.formatDateAbvMonth(data.dateOfFirstUse);

      this.content.pdfPage.respiratoryAssessment[2][date] = this.content.pdfPage.respiratoryAssessment[2][date].replaceAll(',', ', ');
    }
  }
}