export default {
  name: 'modifiedRankingScaleMixin',
  data: () => ({
    content: {
      pdfPage: {
        modifiedRankingScale: [
          { question: "Modified Rankin Scale" }
        ]
      }
    }
  }),
  methods: {
    assignModifiedRankingScale(data, date) {
      this.content.pdfPage.modifiedRankingScale[0][date] = data.modifiedRankingScale;
    },
  }
}