export default {
  name: 'evaluation',
  data: () => ({
    assessmentDiagnosisData: {},
    content: {
      pdfPage: {
        evaluation: [
          { definition: "Status of Assessment" },
          { definition: "Inclusion criteria:  Scale" },
          { definition: "Sum MRC Muscle test" },
          { definition: "Deviation" },
          { definition: "FVC (upright) (L)" },
          { definition: "Deviation" },
          { definition: "FVC (upright) %" },
          { definition: "Deviation" },
          { definition: "6MWT (m)" },
          { definition: "Deviation" },
          { definition: "10MWT (s)" },
          { definition: "Deviation" }
        ]
      }
    }
  }),
  methods: {
    assignEvaluation(data, id, idx) {
      this.content.pdfPage.evaluation[0][id] = 'Signed';
      this.content.pdfPage.evaluation[1][id] = data[idx].assessmentPages[6].modifiedRankingScale;
      this.content.pdfPage.evaluation[8][id] = data[idx].assessmentPages[0].totalDistanceWalked;
      this.content.pdfPage.evaluation[10][id] = data[idx].assessmentPages[3].walking10Meters;
      this.content.pdfPage.evaluation[4][id] = data[idx].assessmentPages[2].fVCUprightL;
      this.content.pdfPage.evaluation[6][id] = data[idx].assessmentPages[2].fVCUprightPredictedNormal ? 
        data[idx].assessmentPages[2].fVCUprightPredictedNormal + ' %'
        : '-';
      this.content.pdfPage.evaluation[2][id] = Number(data[idx].assessmentPages[1].mRCMuscleTest);
      
      if (this.content.pdfPage.evaluation[2][id]) {
        this.content.pdfPage.evaluation[3][id] = this.calculateDeviation({
          fieldIndex: 3,
          fieldName: 'mRCMuscleTest',
          relatedIndex: 1,
          id, idx, data,
        });
      }

      if (this.content.pdfPage.evaluation[4][id]) {
        this.content.pdfPage.evaluation[5][id] = this.calculateDeviation({
          fieldIndex: 5,
          fieldName: 'fVCUprightL',
          relatedIndex: 2,
          id, idx, data,
        });
      }

      if (this.content.pdfPage.evaluation[6][id]) {
        this.content.pdfPage.evaluation[7][id] = this.calculateDeviation({
          fieldIndex: 7,
          fieldName: 'fVCUprightPredictedNormal',
          relatedIndex: 2,
          id, idx, data,
        });
      }

      if (this.content.pdfPage.evaluation[8][id]) {
        this.content.pdfPage.evaluation[9][id] = this.calculateDeviation({
          fieldIndex: 9,
          fieldName: 'totalDistanceWalked',
          relatedIndex: 0,
          id, idx, data,
        });
      }

      if (this.content.pdfPage.evaluation[10][id]) {
        this.content.pdfPage.evaluation[11][id] = this.calculateDeviation({
          fieldIndex: 11,
          fieldName: 'walking10Meters',
          relatedIndex: 3,
          id, idx, data,
        });
      }
    },
    calculateDeviation(params) {
      const { 
        data,
        idx,
        relatedIndex, 
        fieldName
      } = params;

      if (idx > 0) {
        let value1 = Number(data[0].assessmentPages[relatedIndex][fieldName]);
        let value2 = Number(data[idx].assessmentPages[relatedIndex][fieldName]);

        const difference = value1 - value2;
        let percentage = (difference / value1) * 100;
        percentage = percentage * -1;

        const date = this.formatDateAbvMonth(data[0].creationDate)
        return `${percentage > 0 ? '+' : ''}` + (percentage).toFixed(2) + ` % compared to ${date}` ;
      }
    },
  }
}