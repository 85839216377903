export default {
  name: 'vitalSignsMixin',
  data: () => ({
    content: {
      pdfPage: {
        vitalSigns: [
          { question: 'Date of Measurement'},
          { question: 'Height' },
          { question: 'Weight' },
          { question: 'Systolic Blood Pressure '},
          { question: 'Diastolic Blood Pressure '},
          { question: 'Heart Rate' }
        ]
      }
    }
  }),
  methods: {
    assignVitalSigns(data, date) {
      this.content.pdfPage.vitalSigns[0][date] = this.formatDateAbvMonth(data.dateOfVitalSigns);
      this.content.pdfPage.vitalSigns[1][date] = data.heightInCM;
      this.content.pdfPage.vitalSigns[2][date] = data.weightInKG;
      this.content.pdfPage.vitalSigns[3][date] = data.systolicBloodPressure;
      this.content.pdfPage.vitalSigns[4][date] = data.diastolicBloodPressure;
      this.content.pdfPage.vitalSigns[5][date] = data.heartRate;
    }
  }
};
