export default {
  name: 'rotterdamScaleMixin',
  data: () => ({
    content: {
      pdfPage: {
        rotterdam9ItemHandicapScale: [
          {
            question: '1. Mobility indoors: Are you able to move room to room, negotiating doors, carpets and polished surfaces'
          },
          {
            question: '2. Mobility outdoors: Are you able to move outdoors from one place to another, negotiating curbs and uneven grounds'
          },
          {
            question: `3. Kitchen tasks: Are you able to fulfill tasks such as
                      making a pot of tea/coffee and serving it; are you
                      able to collect items from a high and low cupboard,
                      refrigerator, etc.? (Other kitchen tasks are also
                      applicable.)`
          },
          {
            question: `4. Domestic tasks (indoors): Are you able to fulfill
                      house-cleaning tasks, such as vacuum cleaning,
                      dishwashing, doing the laundry, dusting, etc.?`
          },
          {
            question: `5. Domestic tasks (outdoors): Are you able to do the
                      shopping, manage the garden, clean the car, etc.`
          },
          {
            question: `6. Leisure activities (indoors): Are you able to read a
                      newspaper/magazine or a book, use the telephone,
                      fulfill a hobby (other than sporting)`
          },
          {
            question: `7. Leisure activities (outdoors): Are you able to go to
                      a party, theater, movies, concerts, museums,
                      meetings, participate in sport`
          },
          {
            question: `8. Able to drive a car/go by bus/ride a bicycle: Are
                      you able to drive a car, go on a bus/subway, or ride a
                      bicycle`
          },
          {
            question: `9. Work/study: Are you able to fulfill your prior (before
                      becoming ill) job/study`
          }
        ]
      }
    }
  }),
  methods: {
    assignRotterdamScale(data, date) {
      this.content.pdfPage.rotterdam9ItemHandicapScale[0][date] = data.mobilityIndoors;
      this.content.pdfPage.rotterdam9ItemHandicapScale[1][date] = data.mobilityOutdoors;
      this.content.pdfPage.rotterdam9ItemHandicapScale[2][date] = data.kitchenTasks;
      this.content.pdfPage.rotterdam9ItemHandicapScale[3][date] = data.domesticTasksIndoors;
      this.content.pdfPage.rotterdam9ItemHandicapScale[4][date] = data.domesticTasksOutdoors;
      this.content.pdfPage.rotterdam9ItemHandicapScale[5][date] = data.leisureActivitiesIndoors;
      this.content.pdfPage.rotterdam9ItemHandicapScale[6][date] = data.leisureActivitiesOutdoors;
      this.content.pdfPage.rotterdam9ItemHandicapScale[7][date] = data.ableToDriveACarGoByBusRideABicycle;
      this.content.pdfPage.rotterdam9ItemHandicapScale[8][date] = data.workStudy;
    }
  }
};
