export default {
  name: 'mRCScaleMixin',
  data: () => ({
    content: {
      pdfPage: {
        mRCScale: [
          { question: 'Exam Date' },
          { question: 'Shoulder abduction' },
          { question: 'Elbow flexion' },
          { question: 'Wrist flexion' },
          { question: 'Wrist extenstion' },
          { question: 'Thumb abduction' },
          { question: 'Hip flexion' },
          { question: 'Knee extension' },
          { question: 'Ankle dorsiflexion' },
          { question: 'Ankle eversion' },
          { question: 'Ankle inversion' },
          { question: 'Neck extension' },
          { question: 'Shoulder external rotation' },
          { question: 'Knee flexion' },
          { question: 'Ankle plantar flexion' },
          { question: 'Hip extension' },
          { question: 'Hip abduction' },
          { question: 'Hip adduction' },
          { question: 'Elbow extension' },
          { question: 'Neck flexion' }
        ]
      }
    }
  }),
  methods: {
    assignMRCScale(data, date) {
      this.content.pdfPage.mRCScale[0][date] = this.formatDateAbvMonth(data.dateOfMRCScale);
      this.content.pdfPage.mRCScale[1][date] = 
        `Left: ${data.shoulderAbductionLeft || '-'}, Right: ${data.shoulderAbductionRight || '-'}`;
      this.content.pdfPage.mRCScale[2][date] = 
        `Left: ${data.elbowFlexionLeft || '-'}, Right: ${data.elbowFlexionRight || '-'}`;
      this.content.pdfPage.mRCScale[3][date] =
        `Left: ${data.wristFlexionLeft|| '-'}, Right: ${data.wristFlexionRight|| '-'}`;
      this.content.pdfPage.mRCScale[4][date] = 
        `Left: ${data.wristExtensionLeft || '-'}, Right: ${data.wristExtensionRight || '-'}`;
      this.content.pdfPage.mRCScale[5][date] =
        `Left: ${data.thumbAbductionLeft || '-'}, Right: ${data.thumbAbductionRight || '-'}`;
      this.content.pdfPage.mRCScale[6][date] =
        `Left: ${data.hipFlexionLeft || '-'}, Right: ${data.hipFlexionRight || '-'}`;
      this.content.pdfPage.mRCScale[7][date] =
        `Left: ${data.kneeExtensionLeft || '-'}, Right: ${data.kneeExtensionRight || '-'}`;
      this.content.pdfPage.mRCScale[8][date] =
        `Left: ${data.ankleDorsiflexionLeft || '-'}, Right: ${data.ankleDorsiflexionRight || '-'}`;
      this.content.pdfPage.mRCScale[9][date] =
        `Left: ${data.ankleEversionLeft || '-'}, Right: ${data.ankleEversionRight || '-'}`;
      this.content.pdfPage.mRCScale[10][date] =
        `Left: ${data.ankleInversionLeft || '-'}, Right: ${data.ankleInversionRight || '-'}`;
      this.content.pdfPage.mRCScale[11][date] = data.neckExtension || '-';
      this.content.pdfPage.mRCScale[12][date] =
        `Left: ${data.shoulderExternalRotationLeft || '-'}, Right: ${data.shoulderExternalRotationRight || '-'}`;
      this.content.pdfPage.mRCScale[13][date] =
        `Left: ${data.shoulderExternalRotationLeft || '-'}, Right: ${data.shoulderExternalRotationRight || '-'}`;
      this.content.pdfPage.mRCScale[14][date] =
        `Left: ${data.kneeFlexionLeft || '-'}, Right: ${data.kneeFlexionRight || '-'}`;
      this.content.pdfPage.mRCScale[15][date] =
        `Left: ${data.hipExtensionLeft || '-'}, Right: ${data.hipExtensionRight || '-'}`;
      this.content.pdfPage.mRCScale[16][date] =
        `Left: ${data.hipAbductionLeft || '-'}, Right: ${data.hipAbductionRight || '-'}`;
      this.content.pdfPage.mRCScale[17][date] =
        `Left: ${data.hipAdductionLeft || '-'}, Right: ${data.hipAdductionRight || '-'}`;
      this.content.pdfPage.mRCScale[18][date] =
        `Left: ${data.elbowExtensionLeft || '-'}, Right: ${data.elbowExtensionRight || '-'}`;
      this.content.pdfPage.mRCScale[19][date] = data.neckFlexion || '-';
    }
  }
};
