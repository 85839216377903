export default {
  name: 'fatigueSeverityScaleMixin',
  data: () => ({
    content: {
      pdfPage: {
        fatigueSeverityScale: [
          { question: '1. My motivation is lower when I am fatigued' },
          { question: '2. Exercise brings on my fatigue' },
          { question: '3. I am easily fatigued ' },
          { question: '4. Fatigue interferes with my physical functioning' },
          { question: '5. Fatigue causes frequent problems for me' },
          { question: '6. My fatigue prevents sustained physical functioning' },
          { question: '7. Fatigue interferes with carrying out certain duties and responsibilities' },
          { question: '8. Fatigue is among my 3 most disabling symptoms' },
          { question: '9. Fatigue interferes with my work, family, or social life' },
          { question: 'Exam Date' }
        ]
      }
    }
  }),
  methods: {
    assignFatigueSeverityScale(data, date) {
      this.content.pdfPage.fatigueSeverityScale[0][date] = data.motivationIsLowerWhenFatigued;
      this.content.pdfPage.fatigueSeverityScale[1][date] = data.exerciseBringsOnFatigue;
      this.content.pdfPage.fatigueSeverityScale[2][date] = data.easilyFatigued;
      this.content.pdfPage.fatigueSeverityScale[3][date] = data.fatigueInterferesWithPhysicalFunctioning;
      this.content.pdfPage.fatigueSeverityScale[4][date] = data.fatigueCausesFrequentProblems;
      this.content.pdfPage.fatigueSeverityScale[5][date] = data.fatiguePreventsSustainedPhysicalFunctioning;
      this.content.pdfPage.fatigueSeverityScale[6][date] = data.fatigueInterferesWithCarryingOuCertainDuties;
      this.content.pdfPage.fatigueSeverityScale[7][date] = data.fatigueIsAmong3MostDisablingSymptoms;
      this.content.pdfPage.fatigueSeverityScale[8][date] = data.fatigueInterferesWithWork;
      this.content.pdfPage.fatigueSeverityScale[9][date] = this.formatDateAbvMonth(data.dateOfFatigueSeverityScale);
    }
  }
};
